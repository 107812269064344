@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto Slab", serif;
  background-color: #202020;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

h1,
h2,
h4,
h5,
h6,
a,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Staatliches", cursive;
}

p {
  font-size: 1rem;
  font-weight: 300;
}

h1 {
  color: #ffffff;
}

h3 {
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

figure {
  margin: 50px auto;
  transform: scale(1.2);
}

.translateBtn {
  font-family: "Staatliches", cursive;
  cursor: pointer;
}

.text-light-gray {
  color: #d2d2d2;
}

.bit-nav-bar-container {
  display: none;
}

.bit-top-track-button {
  display: none !important;
}

.bit-event {
  padding-top: 35px;
  transition: all 0.3s;
  border-bottom: 1px solid #ffffff;
}

.bit-event:hover {
  transition: all 0.3s;
  background: #ffffff0a;
}

.bit-single-cta {
  background: transparent;
}

.bit-rsvp-container {
  display: none;
}

.bit-play-my-city-button {
  font-size: 24px !important;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  background: #ffffff0a;
}

.bit-date {
  font-size: 28px;
}

.bit-details > div:first-of-type {
  min-width: 40%;
}

.bit-venue,
.bit-location,
.bit-startTime-container,
.bit-startTime,
.bit-no-dates-title {
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto Slab", serif;
  margin-right: 12px;
  margin-left: 12px;
  font-weight: normal !important;
}

.bit-widget .bit-event .bit-date {
  font-weight: normal !important;
}

.bit-widget-container {
  max-width: 1200px;
  margin: 0 auto;
}

.bit-offers-text {
  font-weight: normal !important;
}

.bit-offers.bit-button,
.bit-play-my-city-button,
.bit-upcoming-events-show-all-button {
  font-family: "Staatliches", cursive;
  font-size: 24px !important;
  font-weight: normal !important;
  border: 1px solid #ffffff;
  background: #ffffff0a !important;
  transition: all 0.2s;
}

.bit-offers.bit-button:hover,
.bit-play-my-city-button:hover,
.bit-upcoming-events-show-all-button:hover {
  transition: all 0.2s;
  color: #2b2929 !important;
  background: #ffffff !important;
}

.bit-event-buttons {
  padding-bottom: 15px;
}

.bandcamp-widget-big {
  border: 0;
  width: 360px;
  height: 360px;
  margin: 1rem;
}

.flex-container {
  display: flex;
}

.flex-row {
  max-width: 1200px;
  display: flex;
  margin: 20px auto;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cta-music {
  font-family: "Staatliches", cursive;
  color: #ffffff;
  font-size: 24px;
  line-height: 44px;
  width: 284px;
  border: 1px solid #ffffff;
  background: #ffffff0a;
  transition: all 0.2s;
}

.cta-music:hover {
  transition: all 0.2s;
  color: #2b2929 !important;
  background: #ffffff !important;
}

.card {
  height: 150px;
  width: 320px;
  margin: 10px;
  font-family: "Roboto", Helvetica, sans-serif;
  background: #434343;
  color: #ffffff;
  border-radius: 1%;
  transition: all 0.2s;
}

.card:hover {
  background: #505050;
  transition: all 0.2s;
}

.card-role {
  text-transform: uppercase;
  font-weight: 400;
}

.card-agency {
  font-weight: 300;
}

.card-name {
  font-weight: bold;
}

.card-email {
  color: #4098ff;
  font-family: "Roboto", sans-serif !important;
  text-decoration: underline;
}

.footer {
  background: #111111;
  margin-top: 6rem;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.sponsor-container {
  max-width: 8rem;
  margin: 2rem;
}

.video-container {
  margin: 0.5rem auto;
}

.video {
  width: 70vw;
  height: 40vw;
}

.newsletter-container {
  position: relative;
  height: 465px;
  width: 465px;
}

.vinyl-text a {
  font-family: "Roboto Slab", serif;
}

.vinyl-text {
  position: relative;
  z-index: 1;
}

.vinyl-background {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 0;
  background-image: url(./assets/images/vinyl.png);
  background-repeat: no-repeat;
  background-position: center;
  animation-name: vinyl;
  animation-duration: 3s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.hero-container {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hero_img_txt {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12vw;
  line-height: 10vw;
}

.hero_img {
  min-height: 100vh;
  min-width: 100vw;
  width: auto;
  height: auto;
  object-fit: cover;
}

.hero_subtitle {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2vw;
  font-family: "Roboto Slab", serif;
}

@keyframes vinyl {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 560px) {
  .video {
    width: 300px;
    height: 150px;
  }
  .bandcamp-widget-big {
    width: 250px;
    height: 250px;
  }
  .vinyl-background {
    background-size: 250px 250px;
  }
  .newsletter-container {
    width: 300px;
  }
  .vinyl-text p {
    font-size: 0.8rem;
  }
  .hero_img_txt {
    font-size: 20vw;
    line-height: 16vw;
  }
  p {
    font-size: 14px;
  }
}
.button {
  background-color: white;
  color: #202020;
  border-radius: 4px;
  font-family: "Roboto Slab", serif;
  font-weight: 400;
}

.topNav {
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
  background: rgba(36, 36, 36, 0.01);
  backdrop-filter: blur(20px);
}

.stickyFooter {
  position: fixed;
  overflow: hidden;
  left: 10px;
  bottom: 5%;
  z-index: 2;
}

.social-icon {
  padding: 10px;
}

.social-icon:hover {
  cursor: pointer;
}

.social-icon:hover + .social-icon-name {
  cursor: pointer;
  transition: all 0.2s;
  transform: scale(1.2);
  opacity: 100;
  display: block;
  transition: all 0.2s;
}

.social-icon-name {
  display: none;
  opacity: 0;
  transition: all 0.2s;
  font-family: "Roboto Slab", serif;
  padding-left: 5px;
}

.social-icon-name:hover {
  display: block;
  opacity: 100;
  transform: scale(1.2);
  transition: all 0.2s;
}

.about_img {
  margin: 1.5rem 0;
}

#about figure {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

#shows {
  margin-bottom: 1rem;
}

.blockContent-container p {
  margin-bottom: 1rem;
}

g .social-svg-background {
  color: red;
}
